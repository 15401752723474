import LockIcon from "@material-ui/icons/Lock";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import LocalLibraryOutlinedIcon from "@material-ui/icons/LocalLibraryOutlined";
import BusinessIcon from "@material-ui/icons/Business";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import React from "react";

export const swaggerLinks = [
  {
    icon: <LockIcon />,
    id: "Functions.Auth",
    name: "Auth",
    swaggerUrl: "./specs/auth.json",
    default: true,
    items: [
      {
        id: "authentication/token",
        name: "Get Token",
        swaggerUrl: "post-/authentication/token",
      },
    ],
  },
  {
    icon: <LocalLibraryOutlinedIcon />,
    id: "Functions.Products",
    name: "Products",
    swaggerUrl: "./specs/products.json",
    items: [
      {
        id: "products/pricing",
        name: "Pricing",
        swaggerUrl: "post-/products/pricing",
      },
      {
        id: "products/availability/branchList",
        name: "Branch List Availability",
        swaggerUrl: "post-/products/availability/branchList",
      },
    ],
  },
  {
    icon: <AssignmentTurnedInIcon />,
    id: "Functions.Orders",
    name: "Orders",
    swaggerUrl: "./specs/orders.json",
    items: [
      {
        id: "order/orderNumber",
        name: "Order Details",
        swaggerUrl: "get-/orders/-orderNumber-",
      },
      {
        id: "orders/list",
        name: "Orders List",
        swaggerUrl: "post-/orders/list",
      },
      {
        id: "orders/export",
        name: "Orders export",
        swaggerUrl: "post-/orders/export",
      },
      {
        id: "orders/search",
        name: "Orders Search",
        swaggerUrl: "post-/orders/search",
      },
      {
        id: "order/submit",
        name: "Submit Order",
        swaggerUrl: "post-/orders/submit",
      },
    ],
  },
  {
    icon: <DescriptionOutlinedIcon />,
    id: "Functions.Branches",
    name: "Branches",
    swaggerUrl: "./specs/branches.json",
    items: [
      {
        id: "branches/{branchId}",
        name: "Branch Details",
        swaggerUrl: "get-/branches/-branchId-",
      }
    ]
  },
  {
    icon: <BusinessIcon />,
    id: "Functions.Customers",
    name: "Customers",
    swaggerUrl: "./specs/customers.json",
    items: [
      {
        id: "get-/customers/-customerCode-/shipToAddresses",
        name: "Ship to Adresses",
        swaggerUrl: "get-/customers/-customerCode-/shipToAddresses",
      },
      {
        id: "get-/customers/-customerCode-",
        name: "Details",
        swaggerUrl: "get-/customers/-customerCode-",
      },
      {
        id: "post-/customers/export",
        name: "export",
        swaggerUrl: "post-/customers/export",
      },
      {
        id: "get-/customers/-customerCode-/validate",
        name: "Validate Customer",
        swaggerUrl: "get-/customers/-customerCode-/validate",
      },
    ],
  },
];
