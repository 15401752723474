import React from "react";
import "rapidoc";

const AppRapiDoc = (props) => {
  const { definitionLink } = props;
  return (
    <div id="api-data">
      <rapi-doc
        style={{ height: "100vh", width: "100%" }}
        spec-url={definitionLink.swaggerUrl}
        show-header="false"
        show-side-nav="false"
        show-info="true"
        render-style="view"
        server-url="https://services-qa.heritagelandscapesupplygroup.com/"
        update-route="true"
      />
    </div>
  );
};

export default AppRapiDoc;
