import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { hasChildren } from "../../helpers/app";

const SingleLevel = (props) => {
  const { item, onClick, parent } = props;

  const handleClick = () => {
    if (parent) {
      onClick(parent, item);
    } else {
      onClick(item);
    }
  };

  return (
    <ListItem button onClick={handleClick}>
      <ListItemIcon>{item.icon}</ListItemIcon>
      <ListItemText primary={item.name} />
    </ListItem>
  );
};

const MultiLevel = (props) => {
  const { item, onClick, onChildClick } = props;
  const { items: children } = item;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
    onClick(item);
  };

  return (
    <>
      <ListItem button onClick={handleClick}>
        {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
        <ListItemText primary={item.name} />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children.map((child, key) => (
            <APILink
              key={key}
              parent={item}
              element={child}
              isChild
              updateDefinitionLink={onChildClick}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};

const APILink = (props) => {
  const {
    element,
    updateDefinitionLink,
    selected,
    updateChildSelectionLink,
    parent,
  } = props;
  const Component = hasChildren(element) ? MultiLevel : SingleLevel;
  return (
    <Component
      item={element}
      onClick={updateDefinitionLink}
      onChildClick={updateChildSelectionLink}
      parent={parent}
    />
  );
};

export default APILink;
