//Get Default link if there's any with default = true
export const getDefaultAPILink = (config) => {
  if (config && Array.isArray(config) && config.length > 0) {
    const defaultLink = config.find((element) => element.default);
    //If there's one item with default prop, then return this one as the default, if not then return the first one
    return defaultLink ? defaultLink : config[0];
  }
};

//Get link selected via the url and return it
export const getApiLinkByName = (config, name) => {
  if (config && Array.isArray(config) && config.length > 0 && name) {
    const pathName = name.replace("/", "");
    const selectedLink = config.find((element) => element.name === pathName);
    return selectedLink;
  }
};

export function hasChildren(item) {
  if (!item) {
    return false;
  }

  const { items: children } = item;
  if (children === undefined) {
    return false;
  }

  if (children.constructor !== Array) {
    return false;
  }

  if (children.length === 0) {
    return false;
  }

  return true;
}
