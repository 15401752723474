import React from "react";
import APILink from "./APILink.js";
import logo from "../../assets/heritagelogo.jpg";

const Sidebar = (props) => {
  const {
    updateDefinitionLink,
    swaggerLinks,
    selected,
    updateChildSelectionLink,
  } = props;
  const apiLinks = [];

  swaggerLinks.forEach((element) => {
    const { id } = element;
    apiLinks.push(
      <APILink
        key={id}
        element={element}
        updateDefinitionLink={updateDefinitionLink}
        updateChildSelectionLink={updateChildSelectionLink}
        selected={selected}
      />
    );
  });

  return (
    <div className="side-bar">
      <div className="side-bar-header">
        <img src={logo} alt="logo" />
      </div>
      <div className="side-bar-body">
        <h3>API DOCS</h3>
        {apiLinks}
      </div>
    </div>
  );
};

export default Sidebar;
